import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Square,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import dailyRewardBackground from "@/assets/images/daily-reward/background.png";
import dailyRewardVIPBackground from "@/assets/images/daily-reward/vip-background.png";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useAPI from "@/libs/hooks/api";
import { Trans, useTranslation } from "react-i18next";
import Currency from "./Currency";
import pileOfDiamonds from "@/assets/images/daily-reward/pile-of-diamonds.png";
import AuthContext from "@/context/auth";
import useVisible from "@/libs/hooks/visible";

const DAILY_REWARD_Z_INDEX = 1810;

const Stamp = (props) => (
  <Square position="relative" {...props}>
    <Text transform="rotate(20.12deg)" color="white" fontSize="12">
      <Trans i18nKey="daily_rewards.claimed" />
    </Text>
    <Circle position="absolute" size="58px" border="1px #D9D9D9 solid" />
    <Circle position="absolute" size="64px" border="1px #D9D9D9 solid" />
  </Square>
);

const RewardDay = ({ day, accumulation, reward, vip }) => {
  const bonus = day === 7;
  const current = day === accumulation;
  const claimed = day < accumulation;
  return (
    <VStack
      position="relative"
      height="100%"
      width="100%"
      align="stretch"
      boxShadow={current ? "0px 0px 4px rgba(255, 255, 255, 0.25)" : 0}
      borderRadius="10px"
      gap={0}
    >
      {claimed && (
        <AbsoluteCenter>
          <Stamp />
        </AbsoluteCenter>
      )}

      <Box
        height="22px"
        borderTopRadius="10px"
        bg={current ? "#CE56D7" : bonus ? "#B0832B" : "#804E21"}
      >
        <Text
          align="center"
          color="white"
          lineHeight="22px"
          fontSize="12px"
          opacity={claimed ? 0.3 : 1}
        >
          <Trans i18nKey="daily_rewards.nth_day" values={{ day }} />
        </Text>
      </Box>
      <Flex
        bg={
          current
            ? "linear-gradient(155deg, white 28%, #FED8F6 100%)"
            : bonus
              ? "linear-gradient(180deg, #6E472B 23%, #7F5519 100%)"
              : "linear-gradient(to bottom, #673E18 28%, #734E2C 100%)"
        }
        borderBottomRadius="10px"
        flex={1}
      >
        <Flex
          position="relative"
          direction={bonus ? "row" : "column"}
          gap={bonus ? "8px" : 0}
          align="center"
          m="auto"
        >
          {bonus ? (
            <Image
              src={pileOfDiamonds}
              width="80px"
              opacity={claimed ? 0.3 : 1}
            />
          ) : (
            <Currency size="33px" opacity={claimed ? 0.3 : 1} />
          )}

          <Text
            color={current ? "#CC51D7" : "white"}
            fontSize={bonus ? "20px" : "14px"}
            fontWeight={bonus ? "bold" : "normal"}
            opacity={claimed ? 0.3 : 1}
            fontFamily="Outfit"
          >
            +{reward || 0}
          </Text>
        </Flex>
      </Flex>
      {vip && (
        <Circle
          size="32px"
          bg="white"
          position="absolute"
          right="-12px"
          top="16px"
          fontSize="10px"
          fontWeight="bold"
          borderWidth="2px"
          borderColor={current ? "#CC51D7" : "#A17C46"}
          color={current ? "#CC51D7" : "#A17C46"}
        >
          +500
        </Circle>
      )}
    </VStack>
  );
};

// @todo: get UI params from backend
const DailyReward = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const api = useAPI();
  const visible = useVisible();
  const [accumulation, setAccumulation] = useState(0);
  const [rule, setRule] = useState({ rewards: [] });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const vip = !!user?.isVip;

  const total = useMemo(
    () => rule.rewards?.reduce((acc, cur) => acc + cur + (vip ? 500 : 0), 0),
    [rule, vip]
  );

  const onUserBackToApp = useCallback(async () => {
    if (vip) {
      try {
        const result = await api.checkIn();
        if (result?.reward) {
          setAccumulation(result.accumulation);
          setRule(result.rule);
          onOpen();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [api, onOpen]);

  useEffect(() => {
    if (visible) onUserBackToApp();
  }, [onUserBackToApp, visible]);

  if (!user || !vip) return null;

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay bg="rgba(0,0,0,.92)" zIndex={DAILY_REWARD_Z_INDEX} />
      <ModalBody zIndex={DAILY_REWARD_Z_INDEX + 1} position="relative">
        <ModalContent bg="transparent">
          <Center>
            <Box position="relative">
              <Image
                width="332px"
                pt={vip ? "20px" : 0}
                src={vip ? dailyRewardVIPBackground : dailyRewardBackground}
              />
              {!vip && (
                <Text
                  align="center"
                  position="absolute"
                  top="30px"
                  left="4px"
                  width="80px"
                  fontSize="12px"
                >
                  常駐活動
                </Text>
              )}

              <VStack
                position="absolute"
                top="50px"
                left="30px"
                height="100px"
                text="white"
                align="start"
                gap={0}
                justify="center"
              >
                <Text
                  fontWeight="black"
                  fontStyle="italic"
                  color="white"
                  fontSize="28px"
                  lineHeight="35px"
                  textShadow="3px 1px 1px #78430444"
                >
                  {vip ? (
                    <>
                      VIP 專屬
                      <br />
                      每日加贈500鑽
                    </>
                  ) : (
                    <>
                      每日任務
                      <br />
                      鑽石天天領
                    </>
                  )}
                </Text>
              </VStack>
              <VStack
                align="center"
                position="absolute"
                top="148px"
                width="100%"
                px="28px"
              >
                <Text py="4px" color="white" fontSize="14px">
                  <Trans
                    i18nKey="daily_rewards.title"
                    components={{
                      highlight: (
                        <Text
                          as="span"
                          color="#F6BF54"
                          fontSize="24px"
                          fontWeight="bold"
                          fontFamily="Outfit"
                        />
                      )
                    }}
                    values={{ total }}
                  />
                </Text>
                <Grid
                  h="290px"
                  width="266px"
                  templateColumns="repeat(3, 1fr)"
                  gap={4}
                >
                  {Array.from({ length: 7 }).map((_, day) => (
                    <GridItem key={day} colSpan={day === 6 ? 3 : 1}>
                      <RewardDay
                        reward={rule.rewards[day]}
                        day={day + 1}
                        vip={vip}
                        accumulation={
                          accumulation ? ((accumulation + 6) % 7) + 1 : 0
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
                <Button
                  onClick={onClose}
                  my="8px"
                  height="35px"
                  width="192px"
                  bg="linear-gradient(180deg, #D395DA 1%, #CE67D8 49%, #CC51D7 54%, #D564D7 100%)"
                  _active={{
                    bg: "linear-gradient(180deg, #D395DA 1%, #CE67D8 49%, #CC51D7 54%, #D564D7 100%)"
                  }}
                  _focus={{
                    bg: "linear-gradient(180deg, #D395DA 1%, #CE67D8 49%, #CC51D7 54%, #D564D7 100%)"
                  }}
                  border="1px #F9B5FF solid"
                  borderRadius="40px"
                  color="white"
                >
                  {t("daily_rewards.confirm")}
                </Button>
                <Text color="#C28B32" fontSize="10px">
                  {t("daily_rewards.disclaimer")}
                </Text>
              </VStack>
            </Box>
          </Center>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};

DailyReward.Z_INDEX = DAILY_REWARD_Z_INDEX;

export default DailyReward;
