import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import AuthContext from "@/context/auth";
import apiClient from "./api";

// 查詢 keys 常數
export const queryKeys = {
  guestVideos: "guestVideos",
  creator: "creator",
  explore: "explore",
  gifts: "gifts",
  followedCreators: "followedCreators",
  videos: "videos"
};

// Guest Videos Query


// Creator Query
export const useCreator = (creatorId: string) => {
  return useQuery({
    queryKey: [queryKeys.creator, creatorId],
    queryFn: () => apiClient.getCreator(creatorId),
    enabled: !!creatorId
  });
};

// Follow/Unfollow Mutations
export const useFollowCreator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (creatorId: string) => apiClient.followCreator(creatorId),
    onSuccess: (_, creatorId) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.creator, creatorId] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.followedCreators] });
    }
  });
};

export const useUnfollowCreator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (creatorId: string) => apiClient.unfollowCreator(creatorId),
    onSuccess: (_, creatorId) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.creator, creatorId] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.followedCreators] });
    }
  });
};

// Explore Query
export const useExplore = () => {
  return useQuery({
    queryKey: [queryKeys.explore],
    queryFn: () => apiClient.getExplore()
  });
};

// Gifts Query
export const useGifts = () => {
  return useQuery({
    queryKey: [queryKeys.gifts],
    queryFn: () => apiClient.getGifts()
  });
};

// Videos Feed Query
interface VideoFeedResponse {
  data: Array<{
    id: string;
    creator: {
      id: string;
    };
  }>;
  paginator: {
    next: string | null;
  };
}

// Creator Videos Query
export const useCreatorVideos = (creatorId: string, initialVideoId?: string) => {
  return useInfiniteQuery({
    queryKey: [queryKeys.creator, creatorId, "videos"],
    queryFn: ({ pageParam }) => apiClient.getCreatorVideos(creatorId, pageParam as string),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.length && lastPage.paginator?.next !== null) {
        return lastPage.data[lastPage.data.length - 1].id;
      }
      return undefined;
    },
    initialPageParam: initialVideoId || null,
    enabled: !!creatorId,
    refetchOnWindowFocus: false
  });
};

export const useVideosFeed = (id?: string) => {
  const { user } = useContext(AuthContext);
  return useInfiniteQuery<VideoFeedResponse, Error>({
    queryKey: [queryKeys.videos, !!user],
    queryFn: ({ pageParam }) => {
      return user
        ? apiClient.getVideosFeed(pageParam as string)
        : apiClient.getGuestVideos(pageParam as string);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.length && lastPage.paginator?.next !== null) {
        return lastPage.data[lastPage.data.length - 1].id;
      }
      return undefined;
    },
    initialPageParam: id || null,
    enabled: true,
    refetchOnWindowFocus: false
  });
};
