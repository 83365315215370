import React from "react";
import { Progress } from "@chakra-ui/react";

interface VideoControlsProps {
  position: number | null;
  duration: number | null;
}

const VideoControls: React.FC<VideoControlsProps> = ({ position, duration }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        zIndex: 2
      }}
    >
      <Progress
        value={position || 0}
        max={duration || 100}
        colorScheme="orange"
        size="xs"
        bg="gray.700"
        sx={{
          "& > div": {
            transition: position === 0 ? "none" : "width 0.3s linear"
          }
        }}
      />
    </div>
  );
};

export default VideoControls;
