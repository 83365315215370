import useAPI from "@/libs/hooks/api";
import { Box, Image, SimpleGrid, Square, Text, VStack } from "@chakra-ui/react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import profilePlaceholder from "@/assets/images/profile-placeholder.png";
import Skeletons from "@/components/Skeletons";
import LoadMore from "@/components/LoadMore";
import { FETCHING_STATUS } from "@/constants";
import PlaylistContext from "@/context/playlist";
import VideoPreview from "@/components/VideoPreview";
import Playlist from "@/components/Playlist";
import { playerStore } from "@/libs/stores/player.store";

const SavedVideos = () => {
  const { t } = useTranslation();
  const api = useAPI();

  const { setVideos, videos, status, setStatus, jumpTo } =
    useContext(PlaylistContext);

  const clicksVideo = useCallback((index) => () => playerStore.openPlayList(index), []);

  const loader = useCallback(async () => {
    setStatus(FETCHING_STATUS.FETCHING);
    const { data } = await api.getSavedVideos();
    for (const datum of data) {
      datum.video.creatorId = datum.video.creator.id;
      playerStore.inQueue(datum.video);
    }
    setVideos(
      data.map(({ video, savedAt }) => ({
        savedAt,
        ...video
      }))
    );
    setStatus(FETCHING_STATUS.FINISHED);
  }, [api, setStatus, setVideos]);

  useEffect(() => {
    loader();
  }, [loader]);

  return (
    <Box p={6} color="white">
      <Text fontSize="xl" pb={3}>
        {t("profile.tabs.title.saved_videos")}
      </Text>
      <VStack align="stretch" gap={3}>
        {videos?.length === 0 && status === FETCHING_STATUS.FINISHED && (
          <VStack align="center" pt={10}>
            <Square size={36}>
              <Image src={profilePlaceholder} />
            </Square>
            <Text mt={3}>{t("profile.placeholder")}</Text>
          </VStack>
        )}
        <SimpleGrid columns={3} spacing={2}>
          {videos == null &&
            Array.from({ length: 9 }).map((_, index) => (
              <Skeletons.Video key={index} />
            ))}
          {videos?.map((video, index) => (
            <VideoPreview
              key={video.id}
              onClick={clicksVideo(index)}
              showCreator
              showPrice
              timestamp={video.savedAt}
              {...video}
            />
          ))}
        </SimpleGrid>

        <Playlist
          modal
          loader={loader}
          done={status === FETCHING_STATUS.FINISHED}
        />
        <LoadMore
          loader={loader}
          loading={status === FETCHING_STATUS.FETCHING}
          done={status === FETCHING_STATUS.FINISHED}
        />
      </VStack>
    </Box>
  );
};

export default SavedVideos;
