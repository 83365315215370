import getPublicUrl from "@/libs/get-public-url";
import { Avatar, AvatarBadge } from "@chakra-ui/react";
import { creatorStore } from "@/libs/stores/creator.store";

const CreatorAvatar = ({ id, picture, ...rest }) => {
  const creator = creatorStore.getCreator(id);

  return (
    <Avatar role="button" src={getPublicUrl(creator.picture)} size="lg" {...rest}>
            {creator.isOnline && (
              <AvatarBadge
                position="absolute"
                boxSize=".6em"
                borderWidth=".1em"
                borderColor="primary.100"
                bg="green.500"
                bottom=".3em"
                right=".1em"
              />
            )}
    </Avatar>
  );
};

export default CreatorAvatar;
