import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from "firebase/firestore";
import useAPI from "@/libs/hooks/api";
import { useFirebase } from "@/libs/hooks/firebase";
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, AlertIcon, Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import ChatContext from "@/context/chat";
import FAIcon from "@/components/FAIcon";
import InputArea from "@/components/Chat/InputArea";
import { ref, uploadBytes } from "firebase/storage";
import Header from "@/components/Header";
import AuthContext from "@/context/auth";
import { useTranslation } from "react-i18next";
import usePolyfills from "@/libs/hooks/polyfills";
import Message from "@/components/Chat/Message";
import { transformMessage } from "@/libs/chats";
import { useNavigate } from "react-router-dom";
import ChargeUpdatedNotice from "./Chat/ChargeUpdatedNotice";
import LoadMore from "./LoadMore";
import { chatroomStore } from "@/libs/stores/chatroom.store";
import { creatorStore } from "@/libs/stores/creator.store";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import apiClient from "@/libs/api";
import { observer } from "mobx-react-lite";
import usePWA from "@/libs/hooks/pwa";

const Chatroom = ({ id, creatorId, onCreate, onBack }) => {
  const { isPWA } = usePWA();
  const { t } = useTranslation();
  const { firestore, storage } = useFirebase();
  const { user } = useContext(AuthContext);
  const {
    points: { balance }
  } = user;
  const api = useAPI();
  const navigate = useNavigate();
  const { histories, updateHistory } = useContext(ChatContext);
  const chat = chatroomStore.chatrooms.get(id);
  const [buffered, setBuffered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState("");
  const [updatedCharge, setUpdatedCharge] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const { getMaxHeightRepresentation } = usePolyfills();
  const chatRoomBody = useRef();
  const creator = creatorStore.getCreator(creatorId || chat?.creatorId);
  const maxH = getMaxHeightRepresentation();
  const update = useMemo(() => updateHistory(id), [id, updateHistory]);

  const sortedMessages = useMemo(
    () => {
      return Object.values(histories[id] || {})
        .concat(buffered)
        .sort((a, b) => b.createdAt - a.createdAt)
        .reverse();
    },
    [buffered, histories, id]
  );

  const isFromUser = useCallback(
    (message) =>
      message.id.startsWith("temp") || message.sender.id === user.uid,
    [user.uid]
  );

  const isSameDay = useCallback(
    (index) =>
      index >= 1 &&
      dayjs(sortedMessages[index].createdAt).isSame(
        dayjs(sortedMessages[index - 1].createdAt),
        "day"
      ),
    [sortedMessages]
  );

  const scrollToBottom = useCallback(() => {
    chatRoomBody.current.scrollTop = chatRoomBody.current.scrollHeight;
  }, []);

  const unlockMessage = useCallback(
    (id) => async () => {
      await api.unlockMessage(id);
      // Get fresh message data after unlock
      const messageDoc = await getDocs(doc(firestore, "chatroom-messages", id));
      if (messageDoc.exists()) {
        const freshMessage = transformMessage({ id: messageDoc.id, ...messageDoc.data() });
        update([freshMessage]);
      }
    },
    [api, firestore, update]
  );

  const toCreatorPage = useCallback(
    () => navigate(`/c/@${creator?.slug}`),
    [creator?.slug, navigate]
  );


  const loadMessages = useCallback(async () => {
    if (!id || loading) return;
    setLoading(true);
    console.log("loading messages");
    try {
      let q = query(
        collection(firestore, "chatroom-messages"),
        where("chatroomId", "==", id),
        orderBy("createdAt", "desc"),
        limit(20)
      );

      if (anchor) {
        q = query(
          collection(firestore, "chatroom-messages"),
          where("chatroomId", "==", id),
          orderBy("createdAt", "desc"),
          startAfter(anchor),
          limit(20)
        );
      }

      const res = await getDocs(q);
      const updates = [];
      res.forEach((doc) => {
        updates.push(transformMessage({ id: doc.id, ...doc.data() }));
      });

      update(updates);

      if (res.docs.length > 0) {
        setAnchor(res.docs[res.docs.length - 1]);
        // Calculate scroll position based on new content height
        const oldScrollHeight = chatRoomBody.current.scrollHeight;
        setTimeout(() => {
          const newScrollHeight = chatRoomBody.current.scrollHeight;
          const heightDiff = newScrollHeight - oldScrollHeight;
          chatRoomBody.current.scrollTop = chatRoomBody.current.scrollTop + heightDiff;
        }, 0);
      }
      if (res.docs.length < 20) {
        setAnchor(null);
      }

    } catch (error) {
      console.error("Failed to load messages:", error);
    } finally {
      setLoading(false);
    }
  }, [id, sortedMessages]);

  const sendMessageOrCreateChat = useCallback(
    async (payload) => {
      scrollToBottom();
      const creatorInfo = await apiClient.getCreator(creator.id);
      const freshCharge = creatorInfo.charges.chat;
      if (freshCharge !== creator.chatCharge) {
        setUpdatedCharge(freshCharge);
        setBuffered([]);
        creatorStore.updateCreator(creator, creatorInfo);
        return false;
      }
      if (!id) {
        // new chat
        const result = await api.createChat({
          creatorId,
          ...payload
        });

        const { chatroom } = result;
        if (onCreate) onCreate(chatroom);
      } else {
        // existing chat
        await api.sendMessage(id, payload);
        scrollToBottom();
      }
      return true;
    },
    [scrollToBottom, api, chat, id, onCreate]
  );

  const sendMessage = useCallback(
    async ({ text, type, attachment }) => {
      const tempId = `temp-${Date.now()}`;
      const messageBody = { type, text };

      setBuffered((state) => {
        const newState = state.concat({
          ...messageBody,
          id: tempId,
          buffered: true,
          createdAt: Date.now(),
          [type]: type === "text" ? text : URL.createObjectURL(attachment)
        });
        setTimeout(scrollToBottom, 100); // Add scroll after state update
        return newState;
      });
      if (attachment) {
        const fileName = `${id}-${type}-${Date.now()}`;
        const extensionName = type === "image" ? "png" : "mp4";
        const path = `uploads/users/${user.uid}/${fileName}.${extensionName}`;
        const fileRef = ref(storage, path);
        await uploadBytes(fileRef, attachment);
        messageBody[type] = path;
      }
      return sendMessageOrCreateChat(messageBody);

    },
    [id, sendMessageOrCreateChat, storage, user.uid]
  );

  const sendAudio = useCallback(
    async (blob) => {
      if (!blob) return;
      const tempId = `temp-${Date.now()}`;
      const audio = "";
      setBuffered((state) =>
        state.concat({
          id: tempId,
          type: "audio",
          audio,
          buffered: true,
          createdAt: Date.now()
        })
      );

      const fileName = `${id}-audio-${Date.now()}`;
      const path = `uploads/users/${user.uid}/${fileName}.ogg`;
      const userPictureRef = ref(storage, path);
      await uploadBytes(userPictureRef, blob);

      sendMessageOrCreateChat({ type: "audio", audio: path });
    },
    [id, sendMessageOrCreateChat, storage, user.uid]
  );

  const sendGift = useCallback(
    async (gift) => {
      if (!gift) return;
      const tempId = `temp-${Date.now()}`;
      setBuffered((state) =>
        state.concat({
          id: tempId,
          type: "gift",
          gift,
          buffered: true,
          createdAt: Date.now()
        })
      );

      sendMessageOrCreateChat({ type: "gift", gift });
    },
    [sendMessageOrCreateChat]
  );

  useEffect(() => {
    if (!id) return;
    if (!initialized) {
      setInitialized(true);
      chatroomStore.markChatroomAsRead(id);
      loadMessages().then(() => {
        setTimeout(scrollToBottom, 100);
      });
    }
  }, [api, id, initialized, loadMessages, update, scrollToBottom]);

  // listen messages
  useEffect(() => {
    if (!id) return;
    const q = query(
      collection(firestore, "chatroom-messages"),
      where("chatroomId", "==", id),
      orderBy("createdAt", "desc"),
      limit(22)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updates = [];
      snapshot.docChanges().forEach((change) => {
        if (["added", "modified"].includes(change.type)) {
          const record = { id: change.doc.id, ...change.doc.data() };
          updates.push(transformMessage(record));
        }
      });
      update(updates);
      setBuffered([]);
    });
    return () => unsubscribe();
  }, [api, firestore, id, update]);


  const updateCreatorCharge = useCallback(() => {
    setUpdatedCharge(null);
  }, [updatedCharge]);

  useEffect(() => {
    // check the last message is sent by whom,
    // if it's sent by the other end, mark the message as read
    const lastMessage = sortedMessages[sortedMessages.length - 1];
    if (id && lastMessage && !isFromUser(lastMessage)) {
      chatroomStore.markChatroomAsRead(id);
    }
  }, [api, id, isFromUser, sortedMessages]);

  return (
    <Box
      bgImage="url('https://assets.nightco.io/app/nightco-pattern.png')"
      backgroundSize="cover"
    >
      <Header quickDeposit>
        <Box px={3} role="button" onClick={onBack}>
          <FAIcon type="chevron-left" fontSize="2xl" />
        </Box>
        <Box onClick={toCreatorPage}>
          <Text>{creator?.displayName}</Text>
          <Text fontSize="small">@{creator?.slug}</Text>
        </Box>
      </Header>
      <VStack
        height={`calc(${maxH} - ${Header.HEIGHT}px)`}
        align="stretch"
        position="relative"
        mx="auto"
        borderWidth={{ md: 2 }}
        borderColor="gray.600"
      >
        <Box
          bg="#F6A01E77"
          color="white"
          align="center"
          py={1}
          position="sticky"
          my={0}
        >
          {t("chats.alert.monitor_warning")}
        </Box>
        <Box flex={1} overflow="auto" ref={chatRoomBody}>
          <VStack
            p={3}
            gap={4}
            align="stretch"
            minH={`calc(100% - ${InputArea.HEIGHT}px)`}
            pb={isPWA ? `${InputArea.HEIGHT + 16}px` : `${InputArea.HEIGHT}px`}
          >
            <Box width="96px" alignSelf="center" mb={6} onClick={toCreatorPage}>
              <MobXCreatorAvatar
                id={creator?.id}
                picture={creator?.picture}
                size="2xl"
              />
            </Box>
            <LoadMore loader={loadMessages} loading={loading} done={!anchor} />
            {sortedMessages.map((message, index) => (
              <Fragment key={message.id}>
                {!isSameDay(index) && (
                  <Center py={2}>
                    <Text
                      bg="#4F4F4F"
                      color="#A8A8A8"
                      px={4}
                      borderRadius={20}
                      align="center"
                    >
                      {dayjs(message.createdAt).format("YYYY.MM.DD")}
                    </Text>
                  </Center>
                )}
                <Message
                  {...message}
                  onUnlock={unlockMessage(message.id)}
                  isFromUser={isFromUser(message)}
                />
              </Fragment>
            ))}
          </VStack>
          {balance < creator.chatCharge && (
            <Flex position="absolute" bottom={28} width="100%" justify="center">
              <Alert
                position="relative"
                width={360}
                status="warning"
                variant="subtle"
                borderRadius={8}
                fontWeight={500}
              >
                <AlertIcon />
                {t("chats.warnings.insufficient_balance")}
                <Box
                  as="span"
                  role="button"
                  textDecor="underline"
                  onClick={() => navigate("/payment/deposit")}
                >
                  {t("chats.hints.deposit")}
                </Box>
              </Alert>
            </Flex>
          )}

          <InputArea
            balance={balance}
            charge={creator.chatCharge}
            sendMessage={sendMessage}
            sendAudio={sendAudio}
            sendGift={sendGift}
          />
        </Box>
        <ChargeUpdatedNotice
          charge={creator.chatCharge}
          updatedCharge={updatedCharge}
          onConfirm={updateCreatorCharge}
        />
      </VStack>
    </Box>
  );
};

export default observer(Chatroom);
