import { makeAutoObservable, runInAction } from "mobx";
import {
  collection,
  doc,
  getAggregateFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  sum,
  updateDoc,
  where
} from "firebase/firestore";
import { Chatroom } from "../models/chatroom";
import firebase from "@/libs/firebase.ts";
import { ChatroomSettings } from "@/libs/models/chatroom-settings.ts";
import apiClient from "@/libs/api.ts";

// 初始化 Firestore 引用
const firestore = firebase.firestore;

class ChatroomStore {
  chatrooms: Map<string, Chatroom> = new Map();
  allChatrooms: Chatroom[] = [];
  chatroomSettings: ChatroomSettings = new ChatroomSettings();
  unreadCount: number = 0;
  recordUnreadCount: number = 0;
  gifts: {
    id: string;
    group: string;
    price: number;
  }[] = [];

  private unsubscribeChatrooms?: () => void;
  private unsubscribeSettings?: () => void;

  constructor() {
    makeAutoObservable(this);

    // Listen for auth state changes
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        this.init();
      } else {
        this.cleanup();
      }
    });
  }

  async init() {
    if (!firebase.auth.currentUser) {
      return;
    }

    this.subscribeChatroomSettings();
    await this.subscribeChatrooms();
    void this.getUnreadCount();
    this.loadGifts();
  }

  subscribeChatroomSettings() {
    if (!firebase.auth.currentUser?.uid) return;
    const docId = `chatroom-settings/user-${firebase.auth.currentUser.uid}`;
    const query = doc(firestore, docId);
    console.log("subscribeChatroomSettings", docId);
    this.unsubscribeSettings = onSnapshot(query, async (snapshot) => {
      const data = snapshot.data();
      if (data) {
        this.chatroomSettings.update(data);

        // First, get all pinned chatrooms
        const pinnedChatroomDocs = await Promise.all(
          this.chatroomSettings.pinned.map(id =>
            getDoc(doc(collection(firestore, "chatrooms"), id))
          )
        );

        // Add pinned chatrooms to store
        pinnedChatroomDocs.forEach(doc => {
          if (doc.exists()) {
            const data = doc.data();
            data.id = doc.id;
            const chatroom = new Chatroom(data);
            chatroom.setUserId(firebase.auth.currentUser?.uid || "");
            this.addOrUpdateChatroom(chatroom);
          }
        });

        this.updateChatrooms(this.allChatrooms);
      }
    });
  }

  async subscribeChatrooms() {
    if (!firebase.auth.currentUser?.uid) return;

    // Then set up regular chatroom subscription
    const chatroomQuery = query(
      collection(firestore, "chatrooms"),
      where("members", "array-contains", firebase.auth.currentUser.uid),
      orderBy("lastMessagedAt", "desc"),
      limit(8)
    );

    this.unsubscribeChatrooms = onSnapshot(chatroomQuery, (snapshot) => {
      try {
        const chatrooms = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;

          const existingChatroom = this.chatrooms.get(data.id);
          let chatroom;

          if (existingChatroom) {
            existingChatroom.update(data);
            chatroom = existingChatroom;
          } else {
            chatroom = new Chatroom(data);
          }
          chatroom.setUserId(firebase.auth.currentUser?.uid || "");

          this.addOrUpdateChatroom(chatroom);
          return chatroom;
        });

        runInAction(() => {
          this.updateChatrooms(chatrooms);
          this.updateRecordUnreadCount();
        });
      } catch (e) {
        console.error("Error in chatroom snapshot:", e);
      }
    });
  }

  updateRecordUnreadCount() {
    const oldRecordUnreadCount = this.unreadCount;
    this.recordUnreadCount = this.allChatrooms.reduce((acc, chatroom) => {
      return acc + chatroom.userUnreadTotal;
    }, 0);
    const diff = this.recordUnreadCount - oldRecordUnreadCount;
    if (diff !== 0) {
      this.unreadCount += diff;
      setAppBadge(this.unreadCount);

    }
  }

  async getUnreadCount() {
    const chatroomQuery = query(
      collection(firestore, "chatrooms"),
      where("userId", "==", firebase.auth.currentUser?.uid)
    );

    const snapshot = await getAggregateFromServer(chatroomQuery, {
      unreadCount: sum("userUnreadTotal")
    });

    this.unreadCount = snapshot.data().unreadCount;

    setAppBadge(this.unreadCount);
  }

  loadGifts = () => {
    apiClient.getGifts().then((gifts) => {
      this.gifts = gifts;
    });
  };

  async loadMoreChatrooms(after = this.allChatrooms[this.allChatrooms.length - 1].lastMessagedAt) {
    console.log("loadMoreChatrooms", after);
    const chatroomQuery = query(
      collection(firestore, "chatrooms"),
      where("members", "array-contains", firebase.auth.currentUser!.uid),
      orderBy("lastMessagedAt", "desc"),
      startAfter(after),
      limit(5)
    );
    const docs = await getDocs(chatroomQuery);
    const allChatrooms = docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return new Chatroom(data);
    });
    this.updateChatrooms(allChatrooms);
    return docs.size;
  }

  addOrUpdateChatroom(chatroom: Chatroom) {
    runInAction(() => {
      const existingChatroom = this.chatrooms.get(chatroom.id);
      if (existingChatroom) {
        existingChatroom.update(chatroom);
      } else {
        this.chatrooms.set(chatroom.id, chatroom);
        this.allChatrooms.push(chatroom);
      }
    });
    return chatroom;
  }

  updateChatrooms(chatrooms: Chatroom[]) {
    chatrooms.forEach((chatroom) => {
      this.addOrUpdateChatroom(chatroom);
    });

    const settings = this.chatroomSettings;
    // sort chatrooms by lastMessagedAt and place pinned chatrooms at the top
    this.allChatrooms = Array.from(this.chatrooms.values()).sort((a, b) => {
      if (settings.pinned.includes(a.id) && !settings.pinned.includes(b.id)) {
        return -1;
      }
      if (!settings.pinned.includes(a.id) && settings.pinned.includes(b.id)) {
        return 1;
      }
      return b.lastMessagedAt - a.lastMessagedAt;
    });
  }

  markChatroomAsRead(chatroomId: string, messageId: string) {
    const chatroom = this.chatrooms.get(chatroomId);
    if (chatroom) {
      // chatroom.markAsRead(messageId);
      doc(collection(firebase.firestore, "chatrooms"), chatroomId);

      void updateDoc(doc(collection(firebase.firestore, "chatrooms"), chatroomId), {
        userUnreadTotal: 0
      });
    }
  }


  togglePin = async (chatroomId: string) => {
    const setting = this.chatroomSettings;
    setting.pinned.includes(chatroomId) ? setting.pinned.splice(setting.pinned.indexOf(chatroomId), 1) : setting.pinned.push(chatroomId);
    await this.updateChatroomSettings();
  };

  toggleMute = async (chatroomId: string) => {
    const setting = this.chatroomSettings;
    setting.muted.includes(chatroomId) ? setting.muted.splice(setting.muted.indexOf(chatroomId), 1) : setting.muted.push(chatroomId);
    await this.updateChatroomSettings();
  };

  async updateChatroomSettings() {
    const setting = this.chatroomSettings;
    const chatroomSettingsRef = doc(firestore, `chatroom-settings/user-${firebase.auth.currentUser?.uid}`);
    await setDoc(chatroomSettingsRef, {
      muted: setting.muted,
      pinned: setting.pinned
    }, { merge: true });
    this.updateChatrooms(this.allChatrooms);
  }

  resolveChatUrl = async (creatorId: string) => {
    let chatroom = this.allChatrooms.find((chat) => chat.creatorId === creatorId);
    if (chatroom) return `/chat/${chatroom.id}`;

    const chatroomQuery = query(
      collection(firestore, "chatrooms"),
      where("creatorId", "==", creatorId),
      where("userId", "==", firebase.auth.currentUser?.uid)
    );

    const snapshot = await getDocs(chatroomQuery);
    if (snapshot.size > 0) {
      const doc = snapshot.docs[0];
      const data = doc.data();
      data.id = doc.id;
      chatroom = new Chatroom(data);
      this.addOrUpdateChatroom(chatroom);
    }

    return chatroom ? `/chat/${chatroom.id}` : `/chat/${creatorId}/new`;
  };


  private cleanup() {
    // Unsubscribe from all listeners
    this.unsubscribeChatrooms?.();
    this.unsubscribeSettings?.();

    // Clear all data
    runInAction(() => {
      this.chatrooms.clear();
      this.allChatrooms = [];
      this.chatroomSettings = new ChatroomSettings();
      this.unreadCount = 0;
      this.recordUnreadCount = 0;
      this.gifts = [];
    });
  }


}

export const chatroomStore = new ChatroomStore();

const setAppBadge = (count: number) => {
  if ("setAppBadge" in navigator) {
    navigator.setAppBadge(count);
  }
};
