import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import "swiper/css";
import "swiper/css/virtual";
import useDeviceInfo from "@/libs/hooks/device-info";
import usePolyfills from "@/libs/hooks/polyfills";
import { playerStore } from "@/libs/stores/player.store";
import GestureHint from "@/components/GestureHint";
import MobileNavigation from "@/components/MobileNavigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Virtual } from "swiper/modules";
import VideoPost from "@/components/VideoPost";
import { observer } from "mobx-react-lite";
import { withRetry } from "@/libs/utils/retry";

const PREFETCH_DISTANCE = 2;

const ModalContainer = observer(({ children, onClose }) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();

  return (
    <Modal isOpen={playerStore.active} onClose={onClose} isCentered size="2xl">
      <ModalOverlay bg="rgba(0,0,0,0.6)" />
      <ModalBody>
        <ModalContent height={fullscreen} position="relative">
          <IconButton
            icon={<FAIcon type="chevron-left" fontSize="2xl" />}
            position="absolute"
            left="4"
            top="4"
            zIndex="modal"
            onClick={() => playerStore.clear()}
            variant="ghost"
            color="white"
            _hover={{ bg: "transparent" }}
            _focus={{ boxShadow: "none", bg: "transparent" }}
            aria-label="Back"
          />
          {children}
        </ModalContent>
      </ModalBody>
    </Modal>
  );
});

const Playlist = ({
                    modal = false,
                    onChange,
                    loader = () => {
                    },
                    done = false,
                    autoPlay = true
                  }) => {
  const { getMaxHeightRepresentation } = usePolyfills();
  const fullscreen = getMaxHeightRepresentation();
  const { height } = useDeviceInfo();
  const [swiper, setSwiper] = useState(null);
  const navigate = useNavigate();
  const Container = useMemo(() => (modal ? ModalContainer : Box), [modal]);
  const [isInitialSlide, setIsInitialSlide] = useState(false);

  const onSlideChange = useCallback(
    async ({ activeIndex }) => {
      if (playerStore.videos[activeIndex]) {
        const videoId = playerStore.videos[activeIndex].id;
        console.log("onSlideChange", activeIndex, videoId);
        playerStore.play(videoId);

        if (!modal) {
          navigate(`/videos/${videoId}`, { replace: true });
        } else {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("video", videoId);
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
      if (activeIndex >= playerStore.videos.length - PREFETCH_DISTANCE) {
        await withRetry(() => loader());
      }
    },
    [loader, done]
  );


  const onExit = useCallback(() => {
    console.log("onExit");
    playerStore.clear();
  }, [modal]);

  useEffect(() => {
    if (autoPlay) playerStore.play();

    return () => {
      playerStore.clear();
    };
  }, []);

  useEffect(() => {
    if (swiper && playerStore.currentIndex !== swiper.activeIndex) {
      console.log("swiper.slideTo", playerStore.currentIndex, "from", swiper.activeIndex);
      swiper.slideTo(playerStore.currentIndex, 0);
    }
  }, [swiper, playerStore.currentIndex]);

  return (
    <Container onClose={onExit}>
      <Box id="container__videos">
        <GestureHint />
        <Box
          height={{
            base: `calc(${fullscreen} - ${MobileNavigation.HEIGHT}px)`,
            md: fullscreen
          }}
          overflow="hidden"
          bg="primary.100"
        >
          <Swiper
            modules={[Mousewheel, Virtual]}
            direction="vertical"
            style={{ height: `${height - MobileNavigation.HEIGHT}px` }}
            virtual={{
              addSlidesBefore: 2,
              addSlidesAfter: 2
            }}
            speed={300}
            slidesPerView={1}
            onSwiper={setSwiper}
            initialSlide={playerStore.currentIndex}
            onTransitionEnd={onSlideChange}
            touchReleaseOnEdges={false}
            threshold={0}
            resistanceRatio={0.5}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 0,
              thresholdDelta: 30,
              thresholdTime: 70
            }}
          >
            {playerStore.videos.map((player, index) => (
              <SwiperSlide key={player.id} virtualIndex={index}>
                <VideoPost
                  id={player.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Container>
  );
};

export default observer(Playlist);
