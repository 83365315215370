import React from "react";
import { Img } from "@chakra-ui/react";

interface VideoThumbnailProps {
  src: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ src }) => {
  return (
    <Img
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        objectFit: "cover"
      }}
      src={src}
      alt="video-thumbnail"
    />
  );
};

export default VideoThumbnail;
