import { getIdToken } from "firebase/auth";
import firebase from "./firebase";

const BACKEND_SERVER = import.meta.env.VITE_BACKEND_SERVER;

// API Client 單例
class APIClient {
  async apiRequest(endpoint: string, { optionalAuth = false, ...options }: any = {}) {
    try {
      if (!BACKEND_SERVER) {
        throw new Error("BACKEND_SERVER environment variable is not defined");
      }

      const currentUser = firebase.auth?.currentUser;
      if (!optionalAuth && !currentUser) return null;

      const headers: Record<string, string> = {
        "Content-Type": "application/json"
      };

      const token = currentUser ? await getIdToken(currentUser) : null;
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await fetch(`${BACKEND_SERVER}${endpoint}`, {
        ...options,
        headers
      });

      const acceptedStatuses = [200, 201, 206, 207];
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (acceptedStatuses.includes(response.status)) {
        return await response.json();
      }
      return await response.text();
    } catch (error) {
      console.error("API Request failed:", error);
      throw error;
    }
  }

  async getGuestVideos(start?: string) {
    return this.apiRequest(`/videos/guest?start=${start}`, { optionalAuth: true });
  }

  async getVideosFeed(start?: string) {
    return this.apiRequest(`/feed/videos?start=${start}`);
  }

  async getCreator(creatorId: string) {
    const isSlug = creatorId.startsWith("@");
    const cleanId = isSlug ? creatorId.slice(1) : creatorId;
    const endpoint = isSlug ? `/creators/slug/${cleanId}` : `/creators/${cleanId}`;
    return this.apiRequest(endpoint, { optionalAuth: true });
  }

  async followCreator(creatorId: string) {
    return this.apiRequest(`/followed-creators/${creatorId}`, { method: "POST" });
  }

  async unfollowCreator(creatorId: string) {
    return this.apiRequest(`/followed-creators/${creatorId}`, { method: "DELETE" });
  }

  async getExplore() {
    return this.apiRequest(`/explore`, { optionalAuth: true });
  }

  async getGifts(): Promise<{
    id: string,
    group: string,
    price: number,
  }[]> {
    return this.apiRequest(`/gifts`, { optionalAuth: true });
  }

  async getCreatorVideos(creatorId: string, next?: string | null) {
    return this.apiRequest(`/creators/${creatorId}/videos?next=${next || ''}`, { optionalAuth: true });
  }
}

// 建立 Singleton 實例
const apiClient = new APIClient();

export default apiClient;
