import { useEffect, useState } from "react";
import Skeletons from "./Skeletons";
import { AbsoluteCenter, AspectRatio, Avatar, Box, Flex, Image, Text } from "@chakra-ui/react";
import FAIcon from "./FAIcon";
import getPublicUrl from "@/libs/get-public-url";
import Currency from "./Currency";
import dayjs from "dayjs";

const VideoPreview = ({
                        onClick,
                        thumbnails,
                        thumbnail,
                        timestamp,
                        showCreator,
                        creator,
                        price,
                        showPrice
                      }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const image = new window.Image();
    image.src = thumbnails || thumbnail;
    image.onload = () => setLoaded(true);
  }, [thumbnail, thumbnails]);
  if (!loaded) return <Skeletons.Video />;
  return (
    <Box color="white">
      <Box position="relative" role="button" onClick={onClick}>
        <AspectRatio ratio={9 / 16}>
          <Image loading="lazy" src={thumbnails} rounded={4} />
        </AspectRatio>
        <AbsoluteCenter>
          <FAIcon type="play" fontSize="2xl" position="relative" m="auto" />
        </AbsoluteCenter>
        {showCreator && creator?.picture && (
          <Flex
            p={2}
            top={0}
            left={0}
            position="absolute"
            gap={1}
            align="center"
          >
            <Avatar src={getPublicUrl(creator?.picture)} size="sm" />
          </Flex>
        )}
      </Box>
      {showPrice && price != null && (
        <Flex align="center" gap={1} mt={1}>
          <Currency size={3} />
          <Text fontWeight="bold" fontSize="sm">
            {price || "免費影片"}
          </Text>
        </Flex>
      )}
      {timestamp && (
        <Text fontWeight="bold" fontSize="sm" mb={1}>
          {dayjs(timestamp).format("YYYY/MM/DD")}
        </Text>
      )}
    </Box>
  );
};

export default VideoPreview;
