import withAuthGuard from "@/libs/hoc/auth-guard";

import { useNavigate, useParams } from "react-router-dom";
import Chatroom from "@/components/Chatroom";
import { useCallback } from "react";
import useNavigateBack from "@/libs/hooks/navigate-back";

const ChatPage = () => {
  const { id, creatorId } = useParams();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const onCreate = useCallback(
    (chatroom) => {
      navigate(`/chat/${chatroom.id}`, {
        shallow: true,
        replce: true
      });
    },
    [navigate]
  );

  return (
    <Chatroom
      id={id}
      creatorId={creatorId}
      onCreate={onCreate}
      onBack={navigateBack}
    />
  );
};

export default withAuthGuard(ChatPage);
