import Currency from "@/components/Currency";
import FAIcon from "@/components/FAIcon";
import OrderConfirm from "@/components/OrderConfirm";
import getPublicUrl from "@/libs/get-public-url";
import useAPI from "@/libs/hooks/api";
import usePolyfills from "@/libs/hooks/polyfills";
import {
  AbsoluteCenter,
  Box,
  Button,
  Circle,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const SimpleVideoPlayer = ({ id, active, onClose, poster }) => {
  const api = useAPI();
  const videoRef = useRef(null);
  const [source, setSource] = useState(null);
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();
  useEffect(() => {
    if (id) {
      api.getMessagePlayback(id).then(({ playback }) => setSource(playback));
      if (!videoRef.current) {
        videoRef.current = document.createElement("video");
      }
    }
  }, [api, id]);

  useEffect(() => {


  }, []);

  return (
    <video
      src={source}
      // style={{ width: "100%", height: "100%" }}
      poster={poster}
      ref={videoRef}
      controls
      controlsList="nodownload noplaybackrate "
    />
  );
};

const Media = ({
                 id,
                 type,
                 image,
                 thumbnail,
                 text,
                 buffered,
                 isUnlocked,
                 isFromUser,
                 price,
                 onUnlock
               }) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    onOpen: onConfirmOrderOpen,
    onClose: onConfirmOrderClose,
    isOpen: isConfirmOrderOpen
  } = useDisclosure();

  const unlocked = isUnlocked || isFromUser;

  const imageSource = type === "image" ? image : thumbnail;
  // @todo: imageSource?.blur is for compatibility of legacy schema
  const preview = buffered
    ? imageSource
    : getPublicUrl(imageSource?.blur ?? imageSource ?? "");

  const handleUnlock = useCallback(async () => {
    await onUnlock();
    onConfirmOrderClose();
  }, [onConfirmOrderClose, onUnlock]);

  return (
    <Box role="button" onClick={() => {
      if (unlocked && type === "video") return;
      onOpen();

    }} position="relative">

      {((type === "video" && isUnlocked) && (
        <SimpleVideoPlayer onClick={(e) => {
          e.stopPropagation();
        }} id={id} poster={preview} />
      )) || <Image
        src={preview}
        loading="lazy"
        borderTopLeftRadius={text ? 20 : 0}
        borderTopRightRadius={text ? 20 : 0}
        onLoad={() => setImageLoaded(true)}
      />
      }
      <Box position="absolute" right={3} bottom={2} color="white">
        <FAIcon type={type === "video" ? "film" : "image"} fontSize="xl" />
      </Box>

      {!unlocked && imageLoaded && (
        <AbsoluteCenter>
          <FAIcon variant="regular" type="lock" color="white" fontSize="3xl" />
        </AbsoluteCenter>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0,0,0,.72)" />
        <ModalBody>
          <ModalContent bg="transparent">
            <Box position="relative" paddingBottom={30}>
              {(type === "image" || !isUnlocked) && (
                <Box position="relative">
                  <Image src={preview} width="100%" />
                  <Circle
                    position="absolute"
                    right={3}
                    top={3}
                    size={6}
                    bg="#7B7B7B"
                    color="#ECECEC"
                  >
                    <FAIcon role="button" type="close" onClick={onClose} />
                  </Circle>
                </Box>
              )}
              {/*{type === "video" && isUnlocked && (*/}
              {/*  <SimpleVideoPlayer id={id} active={isOpen} onClose={onClose} />*/}
              {/*)}*/}

              {unlocked || (
                <Box position="absolute" bottom={3} left={0} width="100%">
                  <Button
                    width={300}
                    mx="auto"
                    variant="themed-gradient"
                    onClick={onConfirmOrderOpen}
                    display="flex"
                    align="center"
                    gap={4}
                    id="button__unlock-message-attachement"
                  >
                    <Currency size={6} />
                    {t("chats.messages.actions.unlock_image_with", {
                      price,
                      currency: t("common.currency")
                    })}
                    <Currency size={6} />
                  </Button>
                  <OrderConfirm
                    isOpen={isConfirmOrderOpen}
                    onClose={onConfirmOrderClose}
                    onCancel={onConfirmOrderClose}
                    onConfirm={handleUnlock}
                    entry={t("chats.messages.actions.unlock_image")}
                    amount={-price}
                  />
                </Box>
              )}
            </Box>
          </ModalContent>
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default Media;
