import { useEffect, useState } from "react";

// Cache Storage 快取圖片邏輯
async function fetchAndCacheImage(url) {
  const cache = await caches.open("image-cache");

  // 檢查是否已有快取
  const cachedResponse = await cache.match(url);
  if (cachedResponse) {
    return cachedResponse.url;
  }

  // 從網路下載圖片並快取
  const response = await fetch(url);
  await cache.put(url, response.clone());

  return response.url;
}

// 自訂 Hook：useCachedImage
export function useCachedImage(url) {
  const [cachedUrl, setCachedUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const cacheImage = async () => {
      const cached = await fetchAndCacheImage(url);
      if (isMounted) {
        setCachedUrl(cached);
      }
    };

    if (url) {
      cacheImage();
    }

    return () => {
      isMounted = false;
    };
  }, [url]);

  return cachedUrl;
}