const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export async function withRetry(fn, { maxAttempts = 3, delayMs = 1000 } = {}) {
  let lastError;
  
  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      return await fn();
    } catch (error) {
      lastError = error;
      console.warn(`Attempt ${attempt}/${maxAttempts} failed:`, error);
      
      if (attempt < maxAttempts) {
        await delay(delayMs * attempt); // Exponential backoff
      }
    }
  }
  
  throw lastError;
}
