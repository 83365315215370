import { makeAutoObservable } from "mobx";
import { ChatroomSettings } from "./chatroom-settings";

export class Chatroom {
  id: string = "";
  active: boolean = true;
  creatorId: string = "";
  creatorUnreadTotal: number = 0;
  creators: string[] = [];
  lastMessagedAt: number = 0;
  lastReadMessageId: string = "";
  lastMessage: any;
  members: string[] = [];
  userId = "";
  userUnreadTotal = 0;
  settings: ChatroomSettings;


  constructor(data?: Partial<Chatroom>) {
    this.settings = new ChatroomSettings();
    makeAutoObservable(this);
    if (data) {
      this.update(data);
    }
  }

  get isCreator(): boolean {
    return this.creators.includes(this.userId);
  }

  update(data: Partial<Chatroom>) {
    // Update primitive properties
    Object.keys(data).forEach(key => {
      if (key !== "settings") {
        // @ts-ignore
        this[key] = data[key];
      }
    });

    // Update settings if provided
    if (data.settings) {
      this.settings.update(data.settings);
    }
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  markAsRead(messageId: string) {
    this.lastReadMessageId = messageId;
    if (this.isCreator) {
      this.creatorUnreadTotal = 0;
    } else {
      this.userUnreadTotal = 0;
    }
  }
}
