import { Box, Flex } from "@chakra-ui/react";
import MobileNavigation from "@/components/MobileNavigation";
import Sidebar from "@/components/Sidebar";
import usePolyfills from "@/libs/hooks/polyfills";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import withAuthGuard from "@/libs/hoc/auth-guard";
import withPullToRefresh from "@/libs/hoc/pull-to-refresh";
import DailyReward from "@/components/DailyReward";
import useDeviceInfo from "@/libs/hooks/device-info";
import { DEFAULT_USER_NAME } from "@/constants";
import IncomingMessage from "@/components/IncomingMessage";
import RequestNotificationPermission from "@/components/RequestNotificationPermission";
import { useContext } from "react";
import StreamContext from "@/context/stream";

const ProtectedLayout = ({ user }) => {
  const { isMobile } = useDeviceInfo();
  const { getMaxHeightRepresentation } = usePolyfills();
  const maxH = getMaxHeightRepresentation();
  const { pathname } = useLocation();
  const { expanding } = useContext(StreamContext);
  const isChat = pathname.startsWith("/chat/");
  const isStreamDesktop = pathname.startsWith("/streams/") && !isMobile;
  const hideNavigation = expanding || isChat || isStreamDesktop;
  if (user && user.displayName === DEFAULT_USER_NAME)
    return <Navigate to="/auth/post-register" />;
  return (
    <>
      <Flex position="relative" bg="primary.100" minH={maxH}>
        <IncomingMessage />
        <RequestNotificationPermission />
        <DailyReward />
        <Sidebar />
        <Box
          width={{ base: "100%", md: 640 }}
          mx="auto"
          px={0}
          pb={hideNavigation ? 0 : `${MobileNavigation.HEIGHT}px`}
        >
          <Outlet />
        </Box>
      </Flex>
      {!hideNavigation && <MobileNavigation />}
    </>
  );
};
export default withAuthGuard(withPullToRefresh(ProtectedLayout));
