import Dexie from "dexie";

// Define types for our cache entries
export const db = new Dexie("nightco-cache");

db.version(2).stores({
  creatorCache: "key, data, timestamp", // key is primary key
  exploreCache: "key, data, timestamp",  // key is primary key
  creators: "id, displayName, slug, updatedAt, isOnline"
});

export default db;
