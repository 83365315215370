import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
const IS_LOCAL = import.meta.env.VITE_APP_ENV === "local";

const initializeMixPanel = () => {
  if (IS_LOCAL) {
    console.log("MixPanel tracking disabled in local environment");
    return false;
  }

  if (!MIXPANEL_TOKEN) {
    console.warn("MixPanel token not found");
    return false;
  }

  if (mixpanel.__loaded) {
    return true;
  }

  try {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: IS_LOCAL,
      track_pageview: true,
      persistence: "localStorage"
    });
    return true;
  } catch (error) {
    console.error("MixPanel initialization error:", error);
    return false;
  }
};

const updateMixPanelUser = (user) => {
  if (!user) {
    mixpanel.reset();
    return;
  }

  try {
    mixpanel.identify(user.uid);
    mixpanel.people.set({
      distinct_id: user.uid,
      email: user.email,
      name: user.displayName,
      phone: user.phoneNumber,
      isPWA: window.navigator?.standalone ||
        window.matchMedia("(display-mode: standalone)").matches,
      uid: user.uid,
      providerId: user.providerId
    });
  } catch (error) {
    console.error("MixPanel user update error:", error);
  }
};

export const useMixPanel = (auth) => {
  useEffect(() => {
    if (!initializeMixPanel()) {
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, updateMixPanelUser);
    return () => unsubscribe();
  }, [auth]);
};
