import React from "react";
import { CircularProgress } from "@chakra-ui/react";

const LoadingSpinner: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2
      }}
    >
      <CircularProgress isIndeterminate color="secondary.500" size="60px" />
    </div>
  );
};

export default LoadingSpinner;
