import { Box, Button, Flex, Image, Square, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import profilePlaceholder from "@/assets/images/profile-placeholder.png";
import Skeletons from "@/components/Skeletons";
import CreatorAvatar from "@/components/CreatorAvatar";
import { chatroomStore } from "@/libs/stores/chatroom.store";
import { creatorStore } from "@/libs/stores/creator.store";
import { observer } from "mobx-react-lite";


const FollowedCreators = () => {
  const { t } = useTranslation();
  const { resolveChatUrl } = chatroomStore;
  const creators = creatorStore.followedCreators;
  const navigate = useNavigate();

  const goToChat = async (id) => {
    const chatUrl = await resolveChatUrl(id);
    navigate(chatUrl);
  };

  return (
    <Box p={6} color="white">
      <Text fontSize="xl" pb={3}>
        {t("profile.tabs.title.follows")}
      </Text>
      <VStack align="stretch" gap={3}>
        {creators == null &&
          Array.from({ length: 8 }).map((_, index) => (
            <Skeletons.Creator key={index} />
          ))}
        {creators?.length === 0 && (
          <VStack align="center" pt={10}>
            <Square size={36}>
              <Image src={profilePlaceholder} />
            </Square>
            <Text mt={3}>{t("profile.placeholder")}</Text>
          </VStack>
        )}
        {creators?.map(({ id, picture, slug, displayName }) => (
          <Flex key={id} align="center">
            <Flex
              role="button"
              onClick={() => navigate(`/c/@${slug}`)}
              gap={3}
              flex={1}
              align="center"
            >
              <CreatorAvatar id={id} picture={picture} />
              <Box>
                <Text fontSize="lg">@{slug}</Text>
                <Text mt={1}>{displayName}</Text>
              </Box>
            </Flex>

            <Button
              variant="themed-outline"
              bg="#333333"
              px={6}
              height={8}
              onClick={() => goToChat(id)}
            >
              {t("profile.follower_actions.chat")}
            </Button>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default observer(FollowedCreators);
