import {
  Box,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import MobileNavigation from "@/components/MobileNavigation";
import UserOverridesContext from "@/context/user-overrides";
import useAPI from "@/libs/hooks/api";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { playerStore } from "@/libs/stores/player.store";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FETCHING_STATUS } from "@/constants";
import Skeletons from "@/components/Skeletons";
import PlaylistContext from "@/context/playlist";
import LoadMore from "@/components/LoadMore";
import VideoPreview from "@/components/VideoPreview";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import { creatorStore } from "@/libs/stores/creator.store";
import Playlist from "@/components/Playlist";
import { chatroomStore } from "@/libs/stores/chatroom.store";

const TABS = {
  CREATORS: 0,
  VIDEOS: 1
};

const CustomTab = (props) => (
  <Tab
    color="white"
    borderWidth={0}
    borderBottomWidth={4}
    _selected={{
      borderColor: "secondary.100"
    }}
    fontWeight="bold"
    {...props}
  />
);

const SearchPage = observer(({ type }) => {
  const api = useAPI();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { videos, setVideos, jumpTo } = useContext(PlaylistContext);
  const { resolveChatUrl } = chatroomStore;
  const { overrides, overrideCreator } = useContext(UserOverridesContext);
  const [tab, setTab] = useState(
    type === "creators" ? TABS.CREATORS : TABS.VIDEOS
  );
  const [searchStatus, setSearchStatus] = useState(FETCHING_STATUS.IDLE);
  const [creators, setCreators] = useState(null);
  const [offset, setOffset] = useState(0);
  const [allVideosLoaded, setAllVideosLoaded] = useState(false);

  const keyword = useMemo(
    () => searchParams.get("keyword") || "",
    [searchParams]
  );

  const switchTab = useCallback((e) => setTab(e), []);


  const toggleFollowed = useCallback(
    (id) => async (e) => {
      if (e) e.stopPropagation();
      try {
        await creatorStore.toggleFollow(id);
      } catch (error) {
        toast({
          title: t("errors.follow_failed"),
          status: "error",
          duration: 3000
        });
      }
    },
    []
  );

  const clicksVideo = useCallback((index) => () => {
    // Queue all valid videos
    playerStore.clear();
    for (const video of videos) {
      video.creatorId = video.creator.id;
      playerStore.inQueue(video);
    }
    playerStore.openPlayList(index);
  }, [videos]);

  const searchVideos = useCallback(
    async ({ length, offset }) => {
      const { data, paginator, total } = await api.getVideos({
        keyword,
        offset,
        length
      });

      setVideos((state) => {
        if (!state) {
          // Queue initial videos
          data.forEach(video => {
            if (video && typeof video === "object") {
              video.creatorId = video.creator.id;
              playerStore.inQueue(video);
            }
          });
          return data;
        }

        // Create a Set of existing video IDs for O(1) lookup
        const existingIds = new Set(state.map(video => video.id));

        // Filter out duplicates from new data
        const uniqueNewVideos = data.filter(video => !existingIds.has(video.id));

        // Queue new unique videos
        uniqueNewVideos.forEach(video => {
          if (video && typeof video === "object") {
            video.creatorId = video.creator?.id;
            playerStore.inQueue(video);
          }
        });

        return state.concat(uniqueNewVideos);
      });

      if (paginator) {
        const newOffset = +paginator.offset + +paginator.length;
        setOffset(newOffset);
        if (newOffset >= total) setAllVideosLoaded(true);
      }
    },
    [api, keyword, setVideos]
  );

  const loadMoreVideos = useCallback(
    (length) => async () => {
      setSearchStatus(FETCHING_STATUS.FETCHING);
      await searchVideos({ length, offset });
      setSearchStatus(FETCHING_STATUS.FINISHED);
    },
    [offset, searchVideos]
  );

  // Clear player when route changes
  useEffect(() => {
    playerStore.clear();
  }, [location.pathname]);


  useEffect(() => {
    async function load() {
      setSearchStatus(FETCHING_STATUS.FETCHING);
      setVideos([]);
      const { data } = await api.searchCreators(keyword);
      setCreators(data);
      searchVideos({ length: 9, offset: 0 });
      setSearchStatus(FETCHING_STATUS.FINISHED);
    }

    load();
  }, [api, keyword, searchVideos, setVideos]);

  return (
    <Box>
      <Tabs
        isFitted
        variant="enclosed"
        minH={`calc(100dvh - ${Header.HEIGHT}px - ${MobileNavigation.HEIGHT}px)`}
        display="flex"
        onChange={switchTab}
        flexDir="column"
        defaultIndex={tab}
      >
        <TabList mx={3} px={{ base: 12, md: 20 }}>
          <CustomTab>{t("explore.tabs.creators")}</CustomTab>
          <CustomTab>{t("explore.tabs.videos")}</CustomTab>
        </TabList>

        <TabPanels bg="#1B1B1B" flex={1}>
          <TabPanel color="white" py={8} px={6}>
            <List spacing={3}>
              {creators != null
                ? creators?.map((creator, index) => (
                  <ListItem
                    key={`${creator.id}-${index}`}
                    display="flex"
                    gap={3}
                    alignItems="center"
                    role="button"
                    onClick={() => navigate(`/c/@${creator.slug}`)}
                  >
                    <MobXCreatorAvatar id={creator.id} size="md" />
                    <Box flex={1} textAlign="left">
                      <Text>@{creator.slug}</Text>
                      <Text fontSize="sm">{creator.displayName}</Text>
                    </Box>
                    <Button
                      onClick={toggleFollowed(creator.id)}
                      size="sm"
                      variant="themed-outline"
                    >
                      <FAIcon type={creatorStore.getCreator(creator.id)?.isFollowed ? "check" : "plus"} />
                      {creatorStore.getCreator(creator.id)?.isFollowed
                        ? t("explore.actions.followed")
                        : t("explore.actions.follow")}
                    </Button>
                  </ListItem>
                ))
                : Array.from({ length: 8 }).map((_, index) => (
                  <ListItem key={index}>
                    <Skeletons.Creator />
                  </ListItem>
                ))}
            </List>
          </TabPanel>
          <TabPanel color="white">
            <SimpleGrid columns={3} spacing={2}>
              {videos != null
                ? videos?.map((video, index) => (
                  <VideoPreview
                    key={video.id}
                    {...video}
                    onClick={clicksVideo(index)}
                  />
                ))
                : Array.from({ length: 12 }).map((_, index) => (
                  <Skeletons.Video key={index} />
                ))}
            </SimpleGrid>
            <Playlist
              modal
              loader={loadMoreVideos(3)}
              done={allVideosLoaded}
            />
            <LoadMore
              mt={3}
              loader={videos.length >= 9 ? loadMoreVideos(9) : () => {
              }}
              loading={searchStatus === FETCHING_STATUS.FETCHING}
              done={allVideosLoaded}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
});

export default SearchPage;
