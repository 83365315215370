import { Box, Button, Drawer, DrawerContent, DrawerOverlay, Flex, Text, useToast, VStack } from "@chakra-ui/react";
import FAIcon from "@/components/FAIcon";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAPI from "@/libs/hooks/api";
import AuthContext from "@/context/auth";
import ShareModal from "../ShareModal";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import { observer } from "mobx-react-lite";
import { creatorStore } from "@/libs/stores/creator.store";
import { playerStore } from "@/libs/stores/player.store";
import { chatroomStore } from "@/libs/stores/chatroom.store";
import Chatroom from "@/components/Chatroom";

const CHAT_DRAWER_PARAMS_KEY = "chat.creator";

const VideoActions = ({
                        id
                      }) => {
  const player = playerStore.getVideo(id);
  const [searchParams, setSearchParams] = useSearchParams();
  const { resolveChatUrl } = chatroomStore;
  const active = true;
  const { t } = useTranslation();
  const api = useAPI();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [shareData, setShareData] = useState(null);
  const mobxCreator = creatorStore.getCreator(player?.video?.creatorId);
  const [isLiked, setIsLiked] = useState(!!player?.video?.isLiked);
  const [likedTotal, setLikedTotal] = useState(player?.video?.likedTotal || 0);
  const tags = player?.video?.tags || [];
  const [isSaved, setIsSaved] = useState(!!player?.video?.isSaved);
  const jumpToExplore = useCallback(
    (tag) => () => navigate("/explore", { state: { tag } }),
    [navigate]
  );
  const [creatorId, setCreatorId] = useState(mobxCreator?.id);

  const chatroomId = useMemo(() => {
    return chatroomStore.allChatrooms.find((c) => c.creatorId === creatorId);
  }, [creatorId]);

  const chatOpen = useMemo(
    () => searchParams.has(CHAT_DRAWER_PARAMS_KEY),
    [searchParams]
  );

  const closeChatPreview = useCallback(() => {
    setCreatorId(null);
    setSearchParams({}, { shallow: true });
  }, [setSearchParams]);

  const onCreatorClick = useCallback(
    (creator) => {
      setSearchParams({}, { replace: true });
      navigate(`/c/${creator}`);
    },
    [navigate, setSearchParams]
  );

  // const onChatClick = useCallback(
  //   (id) => {
  //     setCreatorId(id);
  //     setSearchParams({ [CHAT_DRAWER_PARAMS_KEY]: id }, { shallow: true });
  //   },
  //   [setSearchParams]
  // );

  const onChatClick = useCallback(
    async (creator) => {
      setSearchParams({}, { replace: true });
      navigate(await resolveChatUrl(creator));
    },
    [navigate, resolveChatUrl, setSearchParams]
  );

  const toggleSaved = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = isSaved ? api.unsaveVideo : api.saveVideo;

      try {
        await action(id);
        setIsSaved(!isSaved);
      } catch (error) {
        console.error("Failed to toggle save:", error);
        toast({
          title: t("common.error"),
          status: "error"
        });
      }
    },
    [api, id, isSaved, toast, t]
  );

  const toggleLiked = useCallback(
    async (e) => {
      if (e) e.stopPropagation();
      const action = isLiked ? api.unlikeVideo : api.likeVideo;

      try {
        await action(id);
        setIsLiked(!isLiked);
        setLikedTotal(prev => prev + (isLiked ? -1 : 1));
      } catch (error) {
        console.error("Failed to toggle like:", error);
        toast({
          title: t("common.error"),
          status: "error"
        });
      }
    },
    [api, id, isLiked, toast, t]
  );

  const shareVideo = useCallback(async () => {
    const canShare = typeof navigator?.share === "function";
    const shareData = {
      title: `${mobxCreator.displayName}的影片`,
      text: player.video?.description,
      url: `https://${window.location.host}/videos/${id}`
    };
    if (canShare) {
      try {
        await navigator.share(shareData);
        toast({ title: "已分享!", status: "info" });
      } catch (e) {
        console.error(e);
      }
    } else {
      setShareData(shareData);
    }
  }, [mobxCreator, player?.video?.description, toast, id]);

  if (!player) return null;
  return (
    <Flex
      zIndex={3}
      align="flex-end"
      opacity={active ? 1 : 0}
      marginRight={{ base: undefined, md: "-70px" }}
      gap={{ base: 2, md: 9 }}
    >
      <ShareModal data={shareData} onClose={() => setShareData(null)} />
      {(
        <>
          <Box flex={1}>
            <Flex mb={3} align="center" justify="space-between" gap={3} pr={4}>
              <Flex
                gap={4}
                align="center"
                role="button"
                onClick={() => onCreatorClick(creatorId)}
                id="button__video_check-creator"
              >
                <MobXCreatorAvatar id={creatorId} />
                <VStack align="start" textShadow="0.1rem 0.1rem 0.5rem #3D3D3D">
                  <Text fontSize="small">@{mobxCreator?.slug}</Text>
                  <Text fontSize="lg" fontWeight="bold" lineHeight={1}>
                    {mobxCreator?.displayName}
                  </Text>
                </VStack>
              </Flex>

              {user && (
                <Button
                  onClick={() => {
                    creatorStore.toggleFollow(creatorId);
                  }}
                  id="button__video_follow"
                  size="sm"
                  variant="themed-outline"
                >
                  <FAIcon type={mobxCreator.isFollowed ? "check" : "plus"} />
                  {mobxCreator.isFollowed
                    ? t("videos.actions.followed")
                    : t("videos.actions.follow")}
                </Button>
              )}
            </Flex>
            <Text
              role="button"
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              noOfLines={descriptionExpanded ? null : 3}
              textShadow="0.1rem 0.1rem 0.25rem #3D3D3D"
            >
              {player.video?.description}
            </Text>
            <Flex
              gap={2}
              mt={2}
              pr={2}
              onClick={() => setTagsExpanded(!tagsExpanded)}
              whiteSpace="break-spaces"
              flexWrap="wrap"
              maxHeight={tagsExpanded ? null : "3rem"}
              overflow="hidden"
            >
              {tags.map((tag, index) => (
                <Text
                  key={index}
                  fontSize="xs"
                  bg="rgba(0,0,0,.15)"
                  onClick={jumpToExplore(tag)}
                  borderColor="#D7913F"
                  px={2}
                  rounded="xl"
                  borderWidth={1}
                >
                  #{tag}
                </Text>
              ))}
            </Flex>
          </Box>

          <VStack
            gap={4}
            color="white"
            textShadow="0.1rem 0.1rem 0.5rem #3D3D3D"
            transition="opacity .8s"
          >
            <Box
              align="center"
              role="button"
              onClick={shareVideo}
              id="button__video_share"
            >
              <FAIcon variant="regular" type="share" fontSize="2xl" />
            </Box>
            {user && (
              <>
                <Box
                  align="center"
                  role="button"
                  onClick={toggleLiked}
                  id="button__video_like"
                >
                  <FAIcon
                    variant={isLiked ? "solid" : "regular"}
                    type="heart"
                    color={isLiked ? "red.400" : "white"}
                    fontSize="2xl"
                  />
                  <Text fontSize="sm" fontWeight="bold">
                    {likedTotal}
                  </Text>
                </Box>
                <Box
                  align="center"
                  role="button"
                  onClick={toggleSaved}
                  id="button__video_save-video"
                >
                  <FAIcon
                    variant={isSaved ? "solid" : "regular"}
                    type="bookmark"
                    color={isSaved ? "blue.300" : "white"}
                    fontSize="2xl"
                  />
                </Box>

                <Box
                  align="center"
                  role="button"
                  onClick={() => onChatClick(mobxCreator.id)}
                  id="button__video_go-to-chat"
                >
                  <FAIcon variant="regular" type="comments" fontSize="2xl" />
                </Box>
              </>
            )}
            <Box
              align="center"
              role="button"
              onClick={() => playerStore.toggleGlobalMute()}
              id="button__video_volume"
            >
              <FAIcon
                variant="regular"
                type={playerStore.globalMuted ? "volume-slash" : "volume"}
                fontSize="2xl"
              />
            </Box>
          </VStack>
        </>
      )}
      <Drawer
        isOpen={chatOpen}
        onClose={closeChatPreview}
        placement="right"
        size="sm"
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent bg="primary.100">
          <Chatroom
            id={chatroomId}
            creatorId={creatorId}
            onBack={closeChatPreview}
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default observer(VideoActions);
