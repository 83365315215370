import "swiper/css";
import Playlist from "@/components/Playlist";
import { useCallback, useEffect, useState } from "react";
import { useVideosFeed } from "@/libs/queries";
import { Box } from "@chakra-ui/react";
import { playerStore } from "@/libs/stores/player.store";
import { observer } from "mobx-react-lite";


const PlaylistPage = () => {
  const [done, setDone] = useState(false);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useVideosFeed(playerStore.getActivePlayer()?.id);


  const loader = useCallback(async () => {
    if (done || isFetchingNextPage) return;
    try {
      const currentPage = data?.pages?.[data.pages.length - 1];
      if (!currentPage?.data?.length) return;

      // 只處理最新一頁的數據
      const videos = currentPage.data;
      console.log("Processing videos:", videos.length);

      // 添加到播放列表
      for (const video of videos) {
        if (!video.creatorId) {
          video.creatorId = video.creator.id;
        }
        playerStore.inQueue(video);
      }

      // 檢查是否還有下一頁
      if (hasNextPage && !isFetchingNextPage) {
        console.log("Fetching next page");
        await fetchNextPage();
      } else if (!hasNextPage) {
        console.log("No more pages");
        setDone(true);
      }

      return videos[0]?.id;
    } catch (error) {
      console.error("Error loading videos:", error);
    }
  }, [data, done, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    const initializePlaylist = async () => {
      if (!isLoading && data?.pages?.[0] && !playerStore.videos.length) {
        console.log("Initializing playlist");
        await loader();
        playerStore.openPlayList();
      }
    };

    initializePlaylist();
  }, [isLoading, data?.pages?.[0]]);

  return (
    <Box position="absolute" top={0} left={{ base: 0, md: 320 }} right={0}>
      {
        playerStore.active && <Playlist
          loader={loader}
          done={done}
        />
      }


    </Box>
  );
};

export default observer(PlaylistPage);
