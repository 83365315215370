import { FETCHING_STATUS } from "@/constants/";
import { createContext, useCallback, useState } from "react";

const PlaylistContext = createContext({ videos: [] });

export const withPlaylistContext = (Component) => (props) => {
  const [videos, setVideos] = useState([]);
  const [muted, setMuted] = useState(true);
  const [immersive, setImmersive] = useState(false);
  const [index, setIndex] = useState(null);
  const [status, setStatus] = useState(FETCHING_STATUS.IDLE);

  const resetVideos = useCallback(() => {
    setVideos([]);
    setStatus(FETCHING_STATUS.IDLE);
  }, []);

  // there's a possibility that in the playlist there are duplicated videos,
  // so make sure to update all of the identical videos
  const updateVideo = useCallback(
    (id) => (data) => {
      setVideos((state) => {
        const cloned = state.slice();
        const updated = cloned.map((element) =>
          element.id === id
            ? {
              ...element,
              ...data
            }
            : element
        );
        return updated;
      });
    },
    []
  );

  return (
    <PlaylistContext.Provider
      value={{
        videos,
        setVideos,
        resetVideos,
        updateVideo,
        index,
        jumpTo: setIndex,
        status,
        setStatus,
        muted,
        setMuted,
        immersive,
        setImmersive
      }}
    >
      <Component {...props} />
    </PlaylistContext.Provider>
  );
};

export default PlaylistContext;
