import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Text,
  useBoolean,
  useOutsideClick
} from "@chakra-ui/react";
import Header from "@/components/Header";
import FAIcon from "@/components/FAIcon";
import { useCallback, useEffect, useRef, useState } from "react";
import useAPI from "@/libs/hooks/api";
import CreatorAvatar from "@/components/CreatorAvatar";

const ExploreLayout = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [focused, setFocused] = useBoolean(false);
  const [keyword, setKeyword] = useState(searchParams.get("keyword") || "");
  const ref = useRef(null);
  const [recommendations, setRecommendations] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const resetSearch = useCallback(() => {
    setKeyword("");
    setSearchParams({}, { shallow: true });
    navigate("/explore");
  }, [navigate, setSearchParams]);

  /* @todo: implement advanced search */
  const applySearch = useCallback(() => {
    if (location.pathname === "/explore")
      navigate("/explore/creators", { shallow: true });
    setSearchParams({ keyword }, { replace: true });
    setKeyword(keyword);
  }, [keyword, location.pathname, navigate, setSearchParams]);

  // debounce search
  useEffect(() => {
    setRecommendations(null);

    async function fetchRecommendations() {
      if (!keyword) return;
      setIsSearching(true);
      setHasSearched(true);
      try {
        const { data } = await api.searchCreators(keyword);
        setRecommendations(data.length > 0 ? data : null);
      } finally {
        setIsSearching(false);
      }
    }

    const timeout = setTimeout(fetchRecommendations, 300);
    return () => clearTimeout(timeout);
  }, [api, keyword]);

  const searching = !!keyword && focused;

  useOutsideClick({ ref, handler: setFocused.off });

  return (
    <Box>
      <Header px={6} align="center" bg="#151515">
        <Popover
          isOpen={searching}
          placement="bottom"
          autoFocus={false}
          closeOnBlur={false}
        >
          <PopoverAnchor>
            <InputGroup size="sm" color="black">
              <Input
                bg="white"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="搜尋更多創作者、影片..."
                px={4}
                onFocus={setFocused.on}
                ref={ref}
                rounded={20}
              />
              {keyword && (
                <InputRightElement pr={4} role="button" onClick={resetSearch}>
                  <FAIcon type="circle-xmark" color="#B1B1B1" />
                </InputRightElement>
              )}
            </InputGroup>
          </PopoverAnchor>
          <PopoverContent
            border="none"
            width={{ base: "100vw", md: 640 }}
            returnFocusOnClose={false}
          >
            <PopoverBody bg="#363636" p={4}>
              <List fontWeight="semibold" letterSpacing={0.5}>
                <ListItem role="button" onClick={applySearch} py={2}>
                  <FAIcon type="search" fontSize="xl" ml={1} mr={4} />
                  {keyword}
                </ListItem>
                {isSearching ? (
                  <ListItem py={2} display="flex" justifyContent="center">
                    <CircularProgress isIndeterminate color="secondary.500" size="20px" />
                  </ListItem>
                ) : recommendations?.map(({ id, slug, picture }) => (
                  <ListItem
                    key={id}
                    role="button"
                    py={2}
                    display="flex"
                    alignItems="center"
                    onClick={() => navigate(`/c/@${slug}`)}
                  >
                    <CreatorAvatar id={id} picture={picture} size="sm" />
                    <Text ml={2}>{slug}</Text>
                  </ListItem>
                ))}
                {!isSearching && hasSearched && keyword && !recommendations && (
                  <ListItem py={2} textAlign="center">
                    找不到相關結果
                  </ListItem>
                )}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Header>
      <Outlet />
    </Box>
  );
};

export default ExploreLayout;
