import { makeAutoObservable } from "mobx";

export class ChatroomSettings {
  muted: string[] = [];
  pinned: string[] = [];

  constructor(data?: Partial<ChatroomSettings>) {
    makeAutoObservable(this);
    if (data) {
      this.update(data);
    }
  }

  update(data: Partial<ChatroomSettings>) {
    Object.assign(this, data);
  }
}
