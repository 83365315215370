import { useFirebase } from "@/libs/hooks/firebase";
import { createContext, useCallback, useEffect, useState } from "react";
import { onMessage } from "firebase/messaging";

const ChatContext = createContext({ chats: [], resolveChatUrl: () => null });

export const withChatContext = (Component) => (props) => {
  const { messaging } = useFirebase();
  const [incoming, setIncoming] = useState([]);
  const [histories, setHistories] = useState({});


  const updateHistory = useCallback(
    (id) => (updates) => {
      const updater = (state) => {
        const target = state[id] ? { ...state[id] } : {};

        const updatedTarget = updates.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, target);

        return { ...state, [id]: updatedTarget };
      };
      setHistories(updater);
    },
    []
  );


  // listen push notification
  useEffect(() => {
    if (!messaging) return;
    const unsubscribe = onMessage(messaging, (payload) => {
      const { data } = payload;
      const { action, body, image } = data;
      const isNewChatMessage = action?.startsWith("NAVIGATE_TO_CHAT");
      if (isNewChatMessage) {
        const splited = action.split("::");
        const sender = splited[2];
        const message = body
          ? `${sender}: ${body}`
          : `${sender} 傳送了一則訊息`;
        setIncoming((state) => state.concat({ message, image }));
      }
    });
    return () => unsubscribe();
  }, [messaging]);


  return (
    <ChatContext.Provider
      value={{
        incoming,
        setIncoming,
        histories,
        updateHistory
      }}
    >
      <Component {...props} />
    </ChatContext.Provider>
  );
};

export default ChatContext;
