import { useEffect, useRef } from "react";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import debounce from "lodash.debounce";

export const useUpdateLastActive = (firestore, auth) => {
  // ✅ 使用 useRef 來追蹤是否已訂閱
  const isSubscribedRef = useRef(false);

  // ✅ Debounced 更新 Firestore 的 lastActiveAt 欄位
  const lastUpdateRef = useRef(0);

  const updateLastActiveAt = debounce(async (userId) => {
    if (!userId) return;
    const now = Date.now();
    if (now - lastUpdateRef.current < 10000) return; // 防止10秒內重複更新
    lastUpdateRef.current = now;

    const userDocRef = doc(firestore, "users", userId);
    await updateDoc(userDocRef, {
      lastActiveAt: serverTimestamp(),
    });
  }, 60000);

  useEffect(() => {
    // ✅ 防止重複訂閱
    if (isSubscribedRef.current) return;
    isSubscribedRef.current = true;

    // ✅ 監聽 Auth 狀態變化
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        updateLastActiveAt(user.uid);
      }
    });

    // ✅ Visibility API 監聽器
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && auth.currentUser) {
        updateLastActiveAt(auth.currentUser.uid);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // ✅ 清理事件和訂閱
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      unsubscribe();
      isSubscribedRef.current = false;
    };
  }, [firestore, auth, updateLastActiveAt]);
};
