import { Box, Flex, Image, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import useAPI from "@/libs/hooks/api";
import Skeletons from "@/components/Skeletons";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import getPublicUrl from "@/libs/get-public-url";
import VideoPreview from "@/components/VideoPreview";
import FAIcon from "@/components/FAIcon";

import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import Playlist from "@/components/Playlist";
import { useFirebase } from "@/libs/hooks/firebase";

import exploreStore from "@/libs/stores/explore.store";
import LoadMore from "@/components/LoadMore";
import { creatorStore } from "@/libs/stores/creator.store";
import MobXCreatorAvatar from "@/components/MobXCreatorAvatar";
import { playerStore } from "@/libs/stores/player.store";
import { withRetry } from "@/libs/utils/retry";


const Loading = () => (
  <Box p={6}>
    <Skeleton
      aspectRatio={9 / 4}
      mb={8}
      speed={0.4}
      startColor="gray.700"
      endColor="gray.600"
    />
    <Box mb={4}>
      <Skeleton
        width={160}
        height={8}
        speed={0.4}
        startColor="gray.700"
        endColor="gray.600"
      />
      <Flex gap={3} my={6} overflow="auto">
        {Array.from({ length: 8 }).map((_, index) => (
          <SkeletonCircle
            key={`creator-skeleton-${index}`}
            boxSize="16"
            minW="16"
            borderRadius="full"
          />
        ))}
      </Flex>
    </Box>
    {Array.from({ length: 3 }).map((_, index) => (
      <Box key={`group-skeleton-${index}`} mb={4}>
        <Skeleton width={160} height={8} />
        <Flex gap={3} my={6} overflow="auto">
          {Array.from({ length: 4 }).map((_, videoIndex) => (
            <Skeletons.Video
              key={`video-skeleton-${index}-${videoIndex}`}
              width={{ base: 40, md: 60 }}
              minW={{ base: 40, md: 60 }}
            />
          ))}
        </Flex>
      </Box>
    ))}
  </Box>
);


const LazyGroup = ({ group, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: "50px 0px"
  });

  return (
    <Box ref={ref}>
      {inView ? (
        <Group {...group} index={index} />
      ) : (
        <Box p={4}>
          <Skeleton height="200px" startColor="gray.700" endColor="gray.600" />
        </Box>
      )}
    </Box>
  );
};

const Group = observer(({ type, name, items = [], index }) => {
  const store = exploreStore;
  const api = useAPI();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: "50px 200px"
  });
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  // Ensure we have valid data array
  const data = exploreStore.groups[index]?.items || [];

  const loadMoreVideos = async () => {
    console.log("loadMoreVideos");
    if (loading || done || type !== "videos") return;
    setLoading(true);
    try {
      const currentLength = items?.length || 0;
      if (currentLength === 0) return true;
      const isDone = await withRetry(() => exploreStore.loadMoreVideos(api, index, currentLength));

      if (!isDone?.items) {
        setDone(true);
        return isDone;
      }

      for (const doneElement of isDone.items) {
        if (doneElement) {
          doneElement.creatorId = doneElement.creator?.id;
          playerStore.inQueue(doneElement);
        }
      }

      return isDone;
    } finally {
      setLoading(false);
    }
  };

  const onVideoClick = useCallback(
    (videoIndex) => () => {
      console.log("onVideoClick22", videoIndex);
      exploreStore.setCurrentGroupIndex(index);
      // Queue all valid videos
      playerStore.clear();
      for (const currentVideo of exploreStore.currentVideos) {
        if (currentVideo && typeof currentVideo === "object") {
          playerStore.inQueue(currentVideo);
          // exploreStore
        }
      }
      playerStore.openPlayList(videoIndex);
    },
    [data, index]
  );

  return (
    <Box
      as={motion.div}
      initial={{ x: 100, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      mt={4}
      pl={6}
    >
      <Flex
        justify="space-between"
        fontWeight="bold"
        color="white"
        align="center"
      >
        <Text
          fontSize="xl"
          maxW="80%"
          sx={{
            wordBreak: "break-word",
            whiteSpace: "pre-wrap"
          }}
        >
          {name}
        </Text>
        <Box as={Link} to={`/explore/${type}`} fontSize="sm" mr={3}>
          <FAIcon type="angle-right" mr={1} /> 探索更多
        </Box>
      </Flex>
      <Flex gap={{ base: 2, md: 3 }}
            my={6}
            pr={6}
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none"
              }
            }}
      >
        {type === "creators" ? (
          <Box ref={ref} display="flex" gap={{ base: 2, md: 3 }}>
            {inView ? (
              // Sort creators by online status
              [...data].sort((a, b) => {
                const isAOnline = creatorStore.getCreator(a).isOnline || false;
                const isBOnline = creatorStore.getCreator(b).isOnline || false;
                return isBOnline - isAOnline; // Online creators first
              }).map((item, index) => (
                <MobXCreatorAvatar
                  id={item}
                  role="button"
                  key={`creator-${item}-${index}`}
                  size="lg"
                  mr="5px"
                />
              ))
            ) : (
              // Loading skeleton for creators
              Array.from({ length: 8 }).map((_, index) => (
                <SkeletonCircle
                  key={`creator-skeleton-${index}`}
                  boxSize="16"
                  minW="16"
                  borderRadius="full"
                />
              ))
            )}
          </Box>
        ) : (
          data.map((video, index) => {
            const creator = store.creators.get(video.creatorId);
            return (
              <Box
                key={`video-${video.id}-${index}`}
                width={{ base: 32, md: 48 }}
                minW={{ base: 32, md: 48 }}
                onClick={onVideoClick(index)}
              >
                <VideoPreview {...video} creator={creator} />
              </Box>
            );
          })
        )}
        {type === "videos" && (
          <LoadMore
            loader={loadMoreVideos}
            loading={loading}
            ml={4}
            done={done}
          />
        )}
      </Flex>
    </Box>
  );
});

const ExplorePage = observer(() => {
  const api = useAPI();
  const { firestore } = useFirebase();
  const [done, setDone] = useState(false);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && firestore && api) {
      exploreStore.initialize(firestore, api);
      initialized.current = true;
    }
    return () => {
      // Clean up function
      initialized.current = false;
    };
  }, [firestore, api]);

  return (
    <Box>
      <Box>
        {exploreStore.loading ? (
          <Loading />
        ) : (
          <>
            <Box p={6}>
              <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }}
                pagination={{
                  clickable: true
                }}
                spaceBetween={30}
                loop
                modules={[Autoplay, Pagination, Navigation]}
              >
                {exploreStore.banners.map((banner, index) => (
                  <SwiperSlide key={`banners-${index}`}>
                    <Box
                      as="a"
                      width="100%"
                      position="relative"
                      href={banner.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Image
                        src={getPublicUrl(
                          typeof banner === "string" ? banner : banner.image
                        )}
                        width="100%"
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
            <Flex direction="column" overflow="hidden">
              {exploreStore.groups.map((group, index) => (
                <LazyGroup
                  key={group.name}
                  group={group}
                  index={index}
                />
              ))}
            </Flex>
          </>
        )}
      </Box>
      {exploreStore.currentGroupIndex !== null && (
        <Playlist
          modal
          loader={async () => {
            const groupIndex = exploreStore.currentGroupIndex;
            const currentGroup = exploreStore.groups[groupIndex];
            const currentLength = currentGroup?.items?.length || 0;
            const isDone = await exploreStore.loadMoreVideos(api, groupIndex, currentLength);
            console.log("isDone", isDone);
            if (isDone.length) {
              for (const doneElement of isDone) {
                if (doneElement) {
                  console.log("doneElement", doneElement);
                  doneElement.creatorId = doneElement.creator.id;
                  playerStore.inQueue(doneElement);
                }
              }
            }

            setDone(isDone);
            return isDone;
          }}
          done={done}
        />
      )}
    </Box>
  );
});

export default ExplorePage;
